import { BookOpenIcon, ClockIcon, LightBulbIcon } from '@heroicons/react/24/outline'
import classnames from 'classnames'
import { PADDING } from '~/components/landing-pages/constants'

export default function UsersReport() {
  return (
    <section className={classnames('flex flex-col gap-y-4', PADDING)}>
      <h2 className="col-span-full text-2xl font-black">Our users</h2>

      <div
        className={classnames(
          'flex flex-col divide-y divide-brand-light rounded-lg border border-brand-light',
          'md:grid md:grid-cols-3 md:divide-x md:divide-y-0'
        )}>
        <div className="flex flex-col gap-y-1 p-6">
          <div className="text-2xl font-black text-brand-utility">85%</div>
          <div className={classnames('font-bold', 'lg:text-lg')}>Save time in finding end-to-end routes</div>
          <ClockIcon className="size-6 text-brand-dark" />
        </div>

        <div className="flex flex-col gap-y-1 p-6">
          <div className="text-2xl font-black text-brand-utility">79%</div>
          <div className={classnames('font-bold', 'lg:text-lg')}>Gain insights they can’t find anywhere else</div>
          <LightBulbIcon className="size-6 text-brand-dark" />
        </div>

        <div className="flex flex-col gap-y-1 p-6">
          <div className="text-2xl font-black text-brand-utility">77%</div>
          <div className={classnames('font-bold', 'lg:text-lg')}>Understand carrier and operator services better</div>
          <BookOpenIcon className="size-6 text-brand-dark" />
        </div>
      </div>
    </section>
  )
}
